import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './authService/AuthProvider';
import authService from './authService';
//Msal
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authService/authConfig";
import { EventType } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((message) => {
  if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_FAILURE, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.LOGIN_FAILURE].includes(message.eventType)) {
    authService.setGetting(false);
    if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(message.eventType)) {
      window.localStorage.removeItem('aps_DRToken')
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
