import { Form, message } from "antd";
import { useState } from "react";
// helpers
import get from "lodash/get";
import authService from "../../authService";
import { useRouter } from "@uirouter/react";
import useRequest from "../../hooks/useRequest";

const useLogin = () => {
  const [isMFARequired, setIsMFARequired] = useState(false);
  const [form] = Form.useForm();
  const router = useRouter();

  const { isLoading, refetch } = useRequest({
    method: "POST",
    isEnabled: false,
    relativePath: "/dr/login",
    includeAuth: false,
    responsePath: "",
    onError: (e) => {
      if (get(e, "response.status", 0) === 401) {
        if (!isMFARequired) {
          message.error({
            type: "error",
            content: "Las credenciales no coinciden",
          });
        } else {
          message.error({
            type: "error",
            content: "El código MFA no coincide",
          });
        }
      }

      if (get(e, "response.status", 0) === 409) {
        setIsMFARequired(true);
      }
    },
    onSuccess: ({ data }) => {
      const { access_token } = data;
      const rawUserData = access_token.split(".")[1];
      let user = {};
      try {
        user = JSON.parse(atob(rawUserData));
        authService.setDRToken(access_token, user);
      } catch (e) { }

      // router.stateService.go("App")
    },
  });

  return {
    setIsMFARequired,
    isMFARequired,
    isLoading,
    refetch,
    form,
  };
};

export default useLogin;
