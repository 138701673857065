import { ReactComponent as Logo } from '../../assets/siprocal_logo_vertical.svg'
// antd
import Spin from 'antd/lib/spin'

const LoadingUser = () => {
  const labels = "Loading Data"
  return (
    <div
      className="w-full d-flex justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <div className="d-flex flex-column align-items-center">
        <Logo width="8.5rem" style={{ marginBottom: 10 }} />
        <div>
          <Spin spinning={true} style={{ marginRight: 10 }} />
          {labels}
        </div>
      </div>
    </div>
  )
}

export default LoadingUser