import { Button, Form, message } from "antd"
import inputs from "./inputs"
import useRequest from "../../hooks/useRequest"
// helpers
import get from 'lodash/get'
import authService from "../../authService"
import { useRouter } from "@uirouter/react"

const LoginForm = ({ isLoading,
  refetch,
  form }) => {
  const router = useRouter()


  const props = {
    //Alert: { message: error },
    Form: {
      form,
      name: 'Login',
      layout: 'vertical',
      onFinish: vars => {
        const { username, password } = vars
        refetch({ body: { email: username, password } })
      },
      //onFinishFailed: d => {router.stateService.go('App')},
      initialValues: { remember: true },
      hideRequiredMark: true
    },
    //Input: input(isLoading),
    Input: inputs(false)
  }

  return (
    <Form {...props.Form} preserve clearOnDestroy={false}>
      {/* isError && <AlertError {...props.Alert} /> */}
      <Form.Item {...props.Input.User} />
      <Form.Item {...props.Input.Password} />
      <Button {...props.Input.Btn} />
    </Form>
  )
}


export default LoginForm