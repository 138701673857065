import { useReducer } from "react";

const initial = {
  response: {},
  data: [],
  error: null,
  isError: false,
  isLoading: false,
};

const reducer = (state, action) => {
  const actions = {
    setError: (error) => ({ ...state, error, isError: true }),
    setData: (data) => ({
      ...state,
      data,
      error: null,
      isError: false,
    }),
    setIsLoading: (isLoading) => ({ ...state, isLoading }),
    setResponse: (response) => ({
      ...state,
      response,
      error: null,
      isError: false,
    }),
  };

  return actions[action.type]?.(action.val);
};

const useCreateStore = (isEnabled) => {
  const [states, dispatch] = useReducer(reducer, {
    ...initial,
    isLoading: isEnabled,
  });

  const actions = {
    setError: (val) => dispatch({ type: "setError", val }),
    setData: (val) => dispatch({ type: "setData", val }),
    setIsLoading: (val) => dispatch({ type: "setIsLoading", val }),
    setResponse: (val) => dispatch({ type: "setResponse", val })
  };

  return [states, actions];
};

export default useCreateStore;
