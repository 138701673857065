import { Suspense } from 'react'
// antd
import Spin from 'antd/lib/spin'

export default function lazyComponent(Component) {
  return function (props = null) {
    return (
      <Suspense fallback={<Spin />}>
        <Component {...props} />
      </Suspense>
    )
  }
}