import axios from 'axios'
// helpers
import get from 'lodash/get'
import authService from '../authService'
// Antd
import { message } from 'antd'



const axiosRequest = ({
  url,
  headers,
  data: dataRequest,
  method = 'post',
  params = {},
  ...config
}) => {

  return axios({
    url,
    method,
    headers: headers,
    data: dataRequest,
    params,
    ...config
  }) //.catch(onError)
}

export default axiosRequest
