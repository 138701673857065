import { lazy } from 'react'
import lazyComponent from "./lazy"

const Verify = lazy(()=>import('../containers/Verify'))

const states = [
    {
        key:'view_verify',
        url:'verify/:hash',
        name: 'App.Verify',
        title: 'Verify',
        views: {
            "main@App": lazyComponent(Verify)
        }
    }
]

export default states