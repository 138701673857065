import { useRouter } from '@uirouter/react'
// antd
import { RollbackOutlined } from '@ant-design/icons'
import { Button } from "antd";


const CancelBtn = () => {
  const router = useRouter()

  const onClick = () => {
    router.stateService.go('App.Landing')
  }

  const propsButton = {
    icon: <RollbackOutlined />,
    children: 'Cancel',
    size: 'large',
    type: 'primary',
    danger: true,
    block: true,
    className: 'w-1 d-flex align-items-center justify-content-center',
    onClick
  }

  return <Button {...propsButton} />
}

export default CancelBtn