import { lazy } from 'react'
import lazyComponent from "./lazy"

const DeepLinkPush = lazy(() => import("../containers/DeepLinkPush"))

const states = [
    {
        key: 'view_deepLinkPush',
        url: 'deeplink-push',
        name: 'App.DeepLinkPush',
        title: 'DeepLink Push',
        views: {
            "main@App":lazyComponent(DeepLinkPush)
        }
    }
]

export default states