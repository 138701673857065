import { lazy } from 'react'
import lazyComponent from "./lazy"

const ListDevices = lazy(() => import('../containers/ExecutiveInfo'))

const states = [
  {
    key: 'view_list_devices',
    url: 'list-devices',
    name: 'App.ListDevices',
    title: 'Lista de dispositivos',
    views: {
      "main@App": lazyComponent(ListDevices)
    }
  }
]

export default states