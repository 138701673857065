// antd
import Button from 'antd/lib/button'
// hooks
import { useMsal } from '@azure/msal-react'
// auth
import { loginRequest } from '../../authService/authConfig'
import { ReactComponent as Logo } from '../../assets/ms_logo.svg'

const MsalLogin = (props) => {
  const { instance } = useMsal();
  const onClick = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      // props.onSuccess({})
    });
  }

  const propsButton = {
    icon: <Logo />,
    children: 'Sign in with Microsoft',
    size: 'large',
    type: 'default',
    block: true,
    className: 'my-2 d-flex align-items-center justify-content-center',
    onClick
  }

  return <Button {...propsButton} />

}

export default MsalLogin