import { useContext, useEffect, useRef } from "react";
import Context from "../../authService/Context";
import axios from "../../utils/axiosUtil";
import useCreateStore from "./useCreateStore";

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import get from 'lodash/get'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone)

const BASE_PATH = process.env.REACT_APP_API_URL;

const defaultEmptyFunction = () => { };

export default function useRequest({
  basePath = BASE_PATH,
  relativePath,
  responsePath = 'data.data',
  headers = {},
  method = "GET",
  params: rawParams = {},
  body: rawBody = {},
  onSuccess = defaultEmptyFunction,
  onError = defaultEmptyFunction,
  includeAuth = true,
  isEnabled = true,
}) {
  const { user } = useContext(Context);
  const { token, isDrAuthenticated } = user;
  const ref = useRef(isEnabled);
  const [{ data, error, isError, isLoading, response }, actions] =
    useCreateStore(isEnabled);

  const Authorization = `${isDrAuthenticated ? 'DR' : 'Bearer'} ${token}`;
  const url = `${basePath}${relativePath}`;
  const userTimeZone = dayjs.tz.guess()

  const refetch = ({ body = rawBody, params = rawParams }) => {
    !isLoading && actions.setIsLoading(true);
    axios({
      url,
      params,
      data: body,
      method,
      headers: { Authorization: includeAuth ? Authorization : undefined, 'User-Timezone': userTimeZone, ...headers },
    })
      .then((response) => {
        const data = get(response, responsePath, null)
        actions.setData(data);
        actions.setResponse(response);
        onSuccess(response);
      })
      .catch((error) => {
        actions.setError(error);
        onError(error);
      })
      .finally(() => actions.setIsLoading(false));
  };

  useEffect(() => {
    if (ref.current) {
      refetch({ rawBody, rawParams });
      ref.current = false;
    }
  }, [refetch]);

  return {
    response,
    data,
    error,
    isError,
    isLoading,
    refetch,
  };
}
