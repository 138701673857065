import { lazy } from 'react'
import lazyComponent from "./lazy"

const Landing = lazy(() => import('../containers/Landing'))
const Subscription = lazy(() => import('../containers/Subscription'))

const states = [
    {
        key: 'view_landing',
        url: 'landing',
        name: 'App.Landing',
        title: 'Landing',
        views: {
            "main@App": lazyComponent(Landing)
        }
    },
    {
        key: 'view_landing_subcription',
        url: '/subscription/:type/:id',
        name: 'App.Landing.Subcription',
        title: 'Subcription',
        views: {
            "main@App": lazyComponent(Subscription)
        }
    }
]

export default states