import { Card, Divider, notification } from "antd"
// Components
import LoginForm from "./LoginForm"
import MsalLogin from "./MsalLogin"
import CancelBtn from "./CancelBtn"
import { useCurrentStateAndParams, useRouter } from "@uirouter/react"
import { useContext, useEffect } from "react"
import Context from "../../authService/Context"
import get from "lodash/get"
import { map } from "lodash"
import useLogin from "./useLogin"
import MFALogin from "./MFALogin"

const Login = () => {
  const { user } = useContext(Context)
  const router = useRouter()
  const { state } = useCurrentStateAndParams()
  const {
    setIsMFARequired,
    isMFARequired,
    isLoading,
    refetch,
    form
  } = useLogin()

  const loginProps = {
    setIsMFARequired,
    isLoading,
    refetch,
    form
  }

  const onSuccess = data => {
    if (data?.data?.errors) {
      map(data.data.errors, e => {
        notification.error({
          message: e?.message
        })
      })
    } else if (data) {
      router.stateService.go('App.Dashboard')
    }
  }

  useEffect(() => {
    if (get(state, 'name', '') === "App.Login" && get(user, 'token', null)) {
      router.stateService.go("App")
    }
  }, [user])

  const props = {
    bordered: false,
    children:
      !isMFARequired ? (
        <>
          <LoginForm onSuccess={onSuccess} {...loginProps} />
          <Divider type='horizontal'>or</Divider>
          <MsalLogin onSuccess={onSuccess} />
          <CancelBtn />
        </>
      ) : (
        <>
          <MFALogin onSuccess={onSuccess} {...loginProps} />
        </>
      )
    ,
    className: 'p-4'
  }

  return (
    <div className="w-full d-flex justify-content-center align-items-center" style={{ height: '82vh' }}>
      <div className="w-25 d-flex flex-column align-items-center">
        <Card {...props} />
      </div>
    </div>
  )
}

export default Login