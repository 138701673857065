import { useState, useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
//
import Context from "./Context";
//
import axios from "axios";
import authService from "./index";
import { useMsal } from "@azure/msal-react";
import { loginRequestAPI } from "./authConfig";
import { get } from "lodash";

const BASE_PATH = process.env.REACT_APP_API_URL;

export default function AuthProvider(attr) {
  const [value, setValue] = useState(authService.state);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const isDrAuthenticated = get(value, 'user.isDrAuthenticated', false)

  useEffect(() => {
    authService.evalDrLogin()
    authService.subscribe(setValue);
  }, []);

  useEffect(() => {
    fetch(`${BASE_PATH}/products`).then(
      async (resp) => {
        const response = await resp.json()
        authService.setDataSubsCards(response)
      }
    ).finally(() => authService.setLoadingContent(false))
  }, [])

  useEffect(() => {

    if (isAuthenticated) {
      authService.setGetting(true);
      instance
        .acquireTokenSilent({
          ...loginRequestAPI,
          account: accounts[0],
        })
        .then((response) => {
          authService.setLogin(
            response.accessToken,
            response.account.name,
            response.account.username,
            response.account.idTokenClaims.roles
          );
        });
    }
    if (isDrAuthenticated) {
      const rawUserData = value.user.token.split('.')[1]
      let user = {}
      try {
        user = JSON.parse(atob(rawUserData))
      } catch (e) {

      }

      authService.setLogin(value.user.token, user.name, user.username, user.roles, true)
    }
  }, [isAuthenticated, accounts, instance, isDrAuthenticated]);
  // Add accounts and instance as dependencies is generating an error on Logout
  // proccess, do you realy need those dependencies?

  const props = {
    value,
    ...attr,
  };

  return <Context.Provider {...props} />;
}
