// view
import Base from "../containers/Base";
import Login from "../containers/Login";
import executiveInfo from "./excecutiveInfo";
// state
import landing from './landing'
import lazyComponent from "./lazy";
import verify from './verify'
import deepLinkPush from './deepLinkPush'

const states = [
    {
        key: 'view_root',
        url: '/',
        name: "App",
        redirectTo: 'App.Landing',
        title: "Home",
        component: Base,
    },
    {
        key: 'view_login',
        url: 'login',
        name: 'App.Login',
        title: 'Login',
        views: {
            "main@App": lazyComponent(Login)
        }
    },
    ...landing,
    ...executiveInfo,
    ...verify,
    ...deepLinkPush
]

export default states;