import { Button, Form, message } from "antd"
import inputs from "./inputs"
import useRequest from "../../hooks/useRequest"
// helpers
import { useRouter } from "@uirouter/react"

const MFALogin = ({ isLoading,
  refetch,
  form, setIsMFARequired }) => {
  const router = useRouter()

  const onClickBack = () => setIsMFARequired(false)

  const props = {
    //Alert: { message: error },
    Form: {
      form,
      name: 'Login',
      layout: 'vertical',
      onFinish: vars => {
        console.log(form.getFieldsValue(true))
        const { username, password, mfa_key } = form.getFieldsValue(true)
        refetch({ body: { email: username, password, mfa_key } })
      },
      onFinishFailed: d => console.log(d),
      onsubmit: () => { console.log("test") },
      initialValues: { remember: true },
      hideRequiredMark: true
    },
    //Input: input(isLoading),
    Input: inputs(false)
  }

  return (
    <Form {...props.Form} disabled={isLoading} re>
      <p className='fs-6'>Enter an MFA code to complete sign-in</p>
      <Form.Item {...props.Input.mfa} />

      <Button {...props.Input.BtnMFA} htmlType="submit" />

      <Button {...props.Input.BtnBack} onClick={onClickBack} />
    </Form>
  )
}


export default MFALogin