import { lazy, Suspense, useContext } from "react";
import { UIView } from "@uirouter/react";

// Page
import LoadingUser from "./LoadingUser";
import Context from "../../authService/Context";

// layout
const Layout = lazy(() => import("../../layout"));

export default function Base() {
  const { getting, loadingContent } = useContext(Context)

  if (getting || loadingContent) return <LoadingUser />

  return (
    <Suspense fallback={<LoadingUser />}>
      <Layout>
        <UIView name="main" />
      </Layout>
    </Suspense>
  );
}
