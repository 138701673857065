import React from 'react'
// antd
import Input from 'antd/lib/input'
// antd icon
import { UserOutlined, LockOutlined } from '@ant-design/icons'

const inputs = (loading, forgotPass = false) => {
  const props = {
    user: {
      size: 'large',
      prefix: <UserOutlined />,
      disabled: loading,
      placeholder: `user@example.com`
    },
    password: {
      size: 'large',
      prefix: <LockOutlined />,
      disabled: loading,
      placeholder: 'Password'
    },
    mfa: {
      size: 'large',
      length: 6,
    }
  }

  return {
    User: {
      name: 'username',
      label: forgotPass && "Username (email)",
      // label: <Labels id="Login.user"/>,
      rules: [
        {
          required: true
          // message: <Labels id="Login.user"/>
        }
      ],
      children: <Input {...props.user} />
    },
    Password: {
      name: 'password',
      // label: <Labels id="Login.pass"/>,
      rules: [
        {
          required: true
          // message: <Labels id="Login.pass"/>
        }
      ],
      children: <Input.Password {...props.password} />
    },
    Btn: {
      loading,
      size: 'large',
      type: 'primary',
      block: true,
      htmlType: 'submit',
      children: forgotPass ? "Send" : "Login",
      className: 'mt-3'
    },
    BtnMFA: {
      loading,
      size: 'large',
      type: 'primary',
      block: true,
      htmlType: 'submit',
      children: "Enviar",
      className: 'mt-3'
    },
    BtnBack: {
      loading,
      size: 'large',
      block: true,
      danger: true,
      children: 'Cancelar',
      className: 'mt-3'
    },
    mfa: {
      name: 'mfa_key',
      label: "MFA Code:",
      rules: [
        {
          required: true,
          max: 6
        }
      ],
      children: <Input.OTP {...props.mfa} />
    }
  }
}

export default inputs